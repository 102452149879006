import { ClassNames, useInViewport } from "@rocketmakers/armstrong-edge";
import React, { HtmlHTMLAttributes } from "react";

import * as styles from "./transitionBlock.module.scss";

export const TransitionBlock: React.FunctionComponent<
  React.PropsWithChildren<HtmlHTMLAttributes<HTMLDivElement>>
> = ({ children, className, ...rest }) => {
  const container = React.useRef<HTMLDivElement>(null);

  const inViewport = useInViewport(container, {
    rootMargin: "50px",
    once: true,
  });
  return (
    <div
      className={ClassNames.concat(styles.transitionBlock, className)}
      data-visible={inViewport}
      ref={container}
      {...rest}
    >
      {children}
    </div>
  );
};
