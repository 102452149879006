import { PrismicRichText } from "@prismicio/react";
import { ClassNames, useInViewport } from "@rocketmakers/armstrong-edge";
import React, { HtmlHTMLAttributes } from "react";

export const CopyBlock: React.FunctionComponent<{
  slice: { primary: { block_copy: Queries.PrismicStructuredTextType } };
}> = ({ slice }) => {
  return (
    <div className="fixed-width intro">
      <PrismicRichText field={slice.primary.block_copy.richText} />
    </div>
  );
};
