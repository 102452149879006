import { graphql, PageProps } from "gatsby";
import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import Shell from "../components/_layout/shell";

import dartboard from "../assets/images/dartboard.jpg";
import building from "../assets/images/building.jpg";
import greens from "../assets/images/greens.jpg";
import officespace from "../assets/images/officespace.jpg";

// Images for blocks
import strategy from "../assets/images/strategy.svg";
import design from "../assets/images/design.svg";
import culture from "../assets/images/culture.svg";

import dashedLine from "../assets/images/dashed-line.svg";

import "swiper/css";
import "swiper/css/navigation";

import * as styles from "./index.module.scss";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { TransitionBlock } from "../components/transitionBlock";
import { ClassNames } from "@rocketmakers/armstrong-edge";
import { SliceZone } from "@prismicio/react";
import { components } from "../slices";

const Index: React.FC<PageProps<Queries.IndexPageQuery>> = (pageProps) => {
  const instagramFeed = pageProps.data.instagram.nodes;
  return (
    <Shell>
      <div className="view">
        <div className="fixed-width">
          <div className="image-float-block">
            <img src={dartboard} />
            <div className="copy-box">
              <h1>Make them fall in love with you</h1>
              <p className="intro">
                We unleash the power of storytelling to help businesses grow.
                Across communication, culture, and place, we build brands and
                experiences that spark passion and incite action from your
                people and customers.
              </p>
              <a
                className={styles.noMobile}
                href="mailto:hello@neatlinecreative.com"
              >
                Get in touch
              </a>
            </div>
          </div>
        </div>

        <SliceZone
          slices={pageProps.data.prismicHome?.data.body as any}
          components={components}
        />

        <div className="fixed-width">
          <Swiper
            className={styles.instagramSwiper}
            navigation
            modules={[Navigation]}
            breakpoints={{
              650: {
                slidesPerGroup: 1,
                slidesPerView: 1,
                spaceBetween: 0,
              },
              651: {
                slidesPerGroup: 3,
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            {instagramFeed.map((item) => {
              const image = getImage(
                item.localFile?.childImageSharp
                  ?.gatsbyImageData as IGatsbyImageData
              );
              return (
                <SwiperSlide key={item.caption}>
                  <a
                    href={item.permalink || "#"}
                    rel="noopener"
                    target="_blank"
                  >
                    {image && (
                      <GatsbyImage
                        image={image}
                        alt={item.caption || ""}
                        title={item.caption || ""}
                      />
                    )}
                  </a>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </Shell>
  );
};

export const query = graphql`
  query IndexPage {
    prismicHome {
      data {
        heading
        intro_background {
          gatsbyImageData
        }
        intro_copy
        body {
          ... on PrismicHomeDataBodyCopyBlock {
            id
            slice_type
            primary {
              block_copy {
                richText
              }
            }
          }
          ... on PrismicHomeDataBody3ColumnGrid {
            id
            slice_type
            items {
              grid_item_title
              grid_item_copy
              icon {
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicHomeDataBodyImageCopyBlock {
            id
            slice_type
            primary {
              background {
                alt
                gatsbyImageData
                url
              }
              full_bleed
              image_block_copy
              image_block_subtitle
              image_block_title
            }
          }
        }
      }
    }

    instagram: allInstaNode(sort: { fields: timestamp, order: DESC }) {
      totalCount
      nodes {
        timestamp
        caption
        original
        permalink
        localFile {
          childImageSharp {
            gatsbyImageData(height: 300, width: 300)
          }
        }
      }
    }
  }
`;

export default Index;
