import { PrismicRichText } from "@prismicio/react";
import { ClassNames, useInViewport } from "@rocketmakers/armstrong-edge";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { HtmlHTMLAttributes } from "react";
import { TransitionBlock } from "../components/transitionBlock";

export const ImageCopyBlock: React.FunctionComponent<{
  slice: { primary: Queries.PrismicHomeDataBodyImageCopyBlockPrimary };
}> = ({ slice }) => {
  const fullBleed = slice.primary.full_bleed;

  let style: React.CSSProperties = {};
  if (fullBleed) {
    style.backgroundImage = `url(${slice.primary.background?.url})`;
  }
  return (
    <div className={ClassNames.concat(!fullBleed && "fixed-width")}>
      <TransitionBlock
        className={ClassNames.concat(
          "image-float-block",
          fullBleed && "full-bg"
        )}
        style={style}
      >
        {!fullBleed && (
          <GatsbyImage
            image={getImage(slice.primary.background!.gatsbyImageData)!}
            alt={slice.primary.background!.alt || ""}
          />
        )}

        {fullBleed && (
          <div className="fixed-width mobile-only">
            <GatsbyImage
              image={getImage(slice.primary.background!.gatsbyImageData)!}
              alt={slice.primary.background!.alt || ""}
            />
          </div>
        )}

        {fullBleed ? (
          <div className="fixed-width">
            <TransitionBlock className="copy-box">
              <h3>{slice.primary.image_block_subtitle}</h3>
              <h2>{slice.primary.image_block_title}</h2>
              <p>{slice.primary.image_block_copy}</p>
            </TransitionBlock>
          </div>
        ) : (
          <TransitionBlock className="copy-box">
            <h3>{slice.primary.image_block_subtitle}</h3>
            <h2>{slice.primary.image_block_title}</h2>
            <p>{slice.primary.image_block_copy}</p>
          </TransitionBlock>
        )}
      </TransitionBlock>
    </div>
  );
};
