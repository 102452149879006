// extracted by mini-css-extract-plugin
export var blockHeader = "index-module--block-header--5IZZJ";
export var blocks = "index-module--blocks--mV1Mm";
export var container = "index-module--container--2GuoF";
export var dashedLine = "index-module--dashed-line--LMuaK";
export var instagramSwiper = "index-module--instagram-swiper--zKwmq";
export var intro = "index-module--intro--f2L2y";
export var mobileOnly = "index-module--mobile-only--GNSXY";
export var mobileWrap = "index-module--mobile-wrap--aNlaX";
export var noMobile = "index-module--no-mobile--Fk2ir";
export var stickyWrapper = "index-module--sticky-wrapper--AwoGu";