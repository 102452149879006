import { PrismicRichText } from "@prismicio/react";
import { ClassNames, useInViewport } from "@rocketmakers/armstrong-edge";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { HtmlHTMLAttributes } from "react";

import dashedLine from "../assets/images/dashed-line.svg";

import * as styles from "./threeColumnGrid.module.scss";

export const ThreeColumnGrid: React.FunctionComponent<{
  slice: Queries.PrismicHomeDataBody3ColumnGrid;
}> = ({ slice }) => {
  console.log(slice);
  return (
    <div className={ClassNames.concat("fixed-width", styles.blocks)}>
      {slice.items.map((item) => (
        <div>
          <div className={styles.blockHeader}>
            <GatsbyImage
              image={getImage(item.icon!.gatsbyImageData)!}
              alt={item.icon?.alt || ""}
            />
            <h2>{item.grid_item_title}</h2>
          </div>
          <img className={styles.dashedLine} src={dashedLine} />
          <p className={styles.gridItemCopy}>{item.grid_item_copy}</p>
          <img className={styles.dashedLine} src={dashedLine} />
        </div>
      ))}
    </div>
  );
};
